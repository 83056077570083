/* App.css */

html {
  color: #575757;
  line-height: 22px;
}

body::after {
  content: '';
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: -1;

}

body {
  margin: 0;
  font-family: Arial, sans-serif;
  display: block;
}

.container {
  max-width: 1300px;
  min-height: 1200px;
  margin: 0 auto 0 auto;
  overflow: hidden;
  background-color: #dededa;
}

.container.page {
  background: url('') no-repeat top center;

}

.hero-image {
  background-image: url('/public/background.png');
  background-position: 0 -180px;
  background-size: cover;
  width: 100%;
  height: 310px;
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
  padding-top: 50px;
}


.toolNote {
  position: relative;
  color: white;
  font-weight: 600;
  top: 35px;
  right: 100px;
}

.toolNoteLink {
  color: #97a8c3;
}


.lighter-bg {
  background-color: #eeeeee;
}

.social-button {
  background-image: url('/public/linkedin.png');
  background-repeat: no-repeat;
  background-size: contain;
  height: 50px;
  width: 50px;
  margin-top: 10px;
  display: inline-block;
  cursor: pointer;
}

.logo-name {
    background-image: url('/public/chase-ai-logo.png');
    background-repeat: no-repeat;
    background-size: contain;
    height: 70px;
    margin: 10px 0;
    width: 250px;
    display: inline-block;
    cursor: pointer;
    /* border: 1px solid pink; */
    margin-left: 90px;
}


.logo-art {
    background-image: url('/public/cai-logo.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 70px;
    width: 70px;
    margin-top: 10px;
    margin-right: 90px;
    display: block;
    float: right;
    /* border: 1px solid red; */
}


header {

  display: flex;
  /* justify-content: space-between; */
  opacity: .9;
  background-color: #5c5c5c;
  color: white;
  /* transition: background-color 0.3s ease; */

  /* border: 1px solid rgb(0, 128, 11); */
}

nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width: 300px;
  /* border: 1px solid rgb(51, 0, 128); */
  margin-left: auto;
  margin-right: -120px;
  margin-bottom: 10px;
  align-self: self-end;
}

nav a {
  color: #fff;
  text-decoration: none;
}

.button {
  border: 1px solid white;
  width: auto;
  height: 20px;
  padding: 10px 10px;
  display: inline-block;
  border-radius: 3px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.button:hover {
  background-color:  #7388a9;
  border: 1px solid #7388a9;
}

.button:active {
  background-color:  #7388a9;
  border: 1px solid #fff;
}

section {
  background-color: #e5e5e5;
  text-align: center;
  width: 100%;
  background: transparent;
  transition: background-color 0.3s ease;
  display: block;
}

.section-divider {
  display: block;
  margin-bottom: 60px;
}

.card {
  width: 400px;
  height: auto;
  border-radius: 5px;
  overflow: hidden;
  display: inline-block;
  margin: 0 20px;
}

.card-header {
  color: white;
  padding: 10px;
  font-size: 22px;
  min-height: 25px;
  background-color: #7388a9;
}

.card-content {
  font-size: 16px;
  padding: 10px;
  min-height: 190px;
  background-color: #f5f1f0;
  text-align: left;
}

.hero-box {
  text-align: left;
  color: white;
  max-width: 600px;
  height: auto;
  margin-left: 80px;
  padding: 10px;
  background-color: #000000ab;
  border-radius: 5px;
}

.hero-header {
    line-height: 40px;
  display: block;
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 20px;
}

.hero-content {
  color: white;
  display: block;
  margin-bottom: 20px;
}

.hero-call-to-action {
  display: block;
}

.info-section-2 {
  height: auto;
  display: flex;
  justify-content: space-between;
  text-align: left;
  max-width: 1200px;
  margin: 0 auto 0 auto;
}

.info-section-2-label {
  display: block;
  height: 30px;
  text-align: left;
  max-width: 1200px;
  margin: 0 auto 0 auto;
  font-weight: 800;
  padding-left: 10px;
}

.info-section-2-header {
  display: inline-block;
  width: 50%;
  min-height: 100px;
  font-size: 30px;
  font-weight: 300;
  padding-left: 40px;
  text-decoration: underline;
}

.thin-header {
  width: 250px;
}

.info-section-2-content {
  display: inline-block;
  width: 50%;
  min-height: 60px;
  padding-right: 30px;
  padding-left: 30px;
}

.info-section-image {
  float: right;
  height: 225px;
  margin-right: 20px;
  border-radius: 3px;
}


/* App.css */

/* ... existing styles ... */

@media (max-width: 1200px) {
  .container {
    max-width: 100%;
    min-height: auto;
    padding: 8px 0;
    padding-top: 0;
  }

  section {
    margin: 0 auto 0 auto;
  }

  .toolNote {
    display: none;
  }

  .logo-art {
    margin-left: 20px;
    margin-right: 20px;
  }

  .logo-name {
    background-size: 350px;
    margin: 0 auto 0 auto;
    height: 100px;
    width: 355px;
  }

  header {
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
  }

  .card {
    width: 100% !important;
    margin: 20px auto 0 auto;
    border-radius: 0px;
  }

.hero-box {
  margin: 0 auto 5px auto;
  background-color: rgba(49, 49, 49, 0.5);
  border-radius: 5px;

}

.hero-image {
  background-image: unset;
}

.social-button {
  margin-left: 10px;
}

  .info-section-2 {
    flex-direction: column;
    align-items: center;
    padding: 20px;;
  }

  .info-section-2-header, .info-section-2-content {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .info-section-2-header {
    line-height: 30px;
    min-height: auto;
    margin: -30px auto 0 auto;
    text-decoration: none;
  }

  .info-section-image {
    float: left;
    /*height: 200px;*/
    margin-top: 10px;
    margin-right: 0;
    margin-bottom: 20px;
  }
}
