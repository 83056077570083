.container.page {
  background-color: #eee;
}

.activity-section-content {
  text-align: left;
  width: 50%;
  padding: 10px;
}

.activity-section-textarea {

  width: 90%;
  height: 400px;
  border-radius: 3px;
  padding: 10px;

}

button {
  width: 100px;
  height: 35px;
  background-color: #7388a9;
  border: 1px solid #7388a9;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  border-radius: 3px;
}

button:active {
  border-color: #fff;
}

button:disabled {
  cursor: default;
  border: 1px solid #7b7b7b;
  background-color: #7b7b7b;
}

@media (max-width: 1200px) { 

  .activity-section-content {
    text-align: center;
    width: 100%;
  }
  
}